import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center rounded py-4 px-4 bg-light-info" }
const _hoisted_2 = { class: "d-flex h-80px w-80px flex-shrink-0 flex-center position-relative ms-5 me-8" }
const _hoisted_3 = { class: "svg-icon position-absolute opacity-5" }
const _hoisted_4 = { class: "svg-icon svg-icon-3x svg-icon-info position-absolute" }
const _hoisted_5 = { class: "text-gray-600 fw-bold fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("span", _hoisted_3, [
        _createVNode(_component_inline_svg, {
          class: "w-80px h-80px",
          src: "media/icons/duotone/Layout/Layout-polygon.svg"
        })
      ]),
      _createVNode("span", _hoisted_4, [
        _createVNode(_component_inline_svg, { src: "media/icons/duotone/Tools/Compass.svg" })
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}